import React from 'react';
import PageLayout from 'components/pageLayout';
import AdminBettingBlock from 'components/block-elements/admin-betting-block/admin-betting-block';
import { Helmet } from 'react-helmet'
import AdminSubNav from 'components/navigations/admin-subnavigation/admin-subnavigation';

const ImagesBlock = (props) => {
  return (
    <>
    <Helmet title='Admin Panel Betting' />
      <PageLayout>
        <AdminSubNav/>
        <AdminBettingBlock {...props}/>
      </PageLayout>
    </>
  );
};

export default ImagesBlock;
