import React, { useState, useEffect } from "react";
import styles from './admin-betting-block.module.less';
import Modal from "../modal/modal";
import OddsService from 'services/odds-service';
import MatchListItemBet from "../match-list-item-bet-copy/match-list-item-bet-copy";
import Loader from 'components/block-elements/loader-css/loader-css';

const AdminBettingBlock = ( props ) => {

    const ClientSideOnlyLazy = React.lazy(() =>
        import("components/block-elements/admin-bet-suggestion-block/admin-bet-suggestion-block")
      )

    const [search, setSearch] = useState('');
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const [existBets, setExistBets] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);
    const [pagesCount, setPagesCount] = useState(null);
    const [filter, setFilter] = useState({
        searchText: '',
        page: 0
    });
    const [loading, setLoading] = useState(true);
    const [isFirstLoading, setIsFirstLoading] = useState(false);
    const [currentBetsData, setCurrentBetsData] = useState({
        id: null,
        coefficient: null,
        description: '',
        stakeLevel: '',
        tip: null,  
        percentValuation: '',
        isBetShow: true,
        betStatus: null,
        bettingData: []
    });


    const closeModal = () => {
        setIsModalOpen(false);
    }

    const openModal = () => {
        setCurrentBetsData({...currentBetsData,
            id: null,
            coefficient: null,
            description: '',
            stakeLevel: '',
            tip: null,  
            percentValuation: '',
            isBetShow: true,
            betStatus: null,
            bettingData: []
        });

        setIsModalOpen(true);

    }

    const deleteBet = async(el) => {
        setLoading(true);
        await OddsService.deleteBetById(el);
        await getAllBets();
        setLoading(false);
    }

    const isSSR = typeof window === "undefined";

    const getAllBets = async() => {
        await OddsService.getAllBets(filter)
        .then(res => {
                setLoading(false);
                setExistBets(res.data.data);
                setPagesCount(Array.from(Array(Math.ceil(res.data.total / 5)).keys()));
            }).catch(err => {
            })
    }

    const searchBets = async() => {
        setLoading(true)
        setFilter({...filter, searchText: search, page: 0});
    }

    const chagePage = async (event) => {
        setLoading(true)
        setFilter({ ...filter, page: event.target.name });
      };

    // useEffect(() => {
    //     getAllBets();
    // }, filter)

    const openExistBetInfo = ( el ) => {
        setCurrentBetsData({...currentBetsData, 
            id: el.id,
            betStatus: el.betStatus,
            coefficient: el.coefficient,
            description: el.description,
            isBetShow: el.isBetShow,
            tip: el.tip,
            stakeLevel: el.stakeLevel,
            percentValuation: el.percentValuation,
            bettingData: el.bettingData,
            mcmBetTo: el.mcmBetTo
        });

        setIsModalOpen(true);
    }

    useEffect(() => {
        // clearInterval(timeoutId);
        // setTimeoutId(setInterval(getAllBets, 5000));
        getAllBets();
      }, filter);

    return <div className={styles.mainContainer}>
        <div className={styles.createBettBlock}>
            <button onClick={openModal}>Add new Bet</button>
        </div>
        <div className={styles.searchBlock}>
            <input value={search} onChange={e => setSearch(e.target.value)}></input>
            <button onClick={searchBets}>Search</button>
        </div>

        <h4>Existing bets:</h4>
        <div className={styles.flexCenter}>
        {!loading ?
            <div className={styles.existBetsBlock}>
                {existBets?.map(el => (
                    <div key={el.id} onClick={() => openExistBetInfo(el)}>
                        <MatchListItemBet
                            deleteBet={deleteBet} 
                            isChange 
                            key={el.id} 
                            {...el} />
                    </div>
                ))}

                <div className={styles.flexButtonGroup}>
                    <div className={styles.buttonsGroups}>
                        {pagesCount &&
                        pagesCount.length > 0 &&
                        pagesCount.map((el, i) => {
                            return (
                            <button
                                className={`${el == filter.page ? styles.activePagButton : ''} ${styles.button}`}
                                name={el}
                                onClick={chagePage}>
                                {el + 1}
                            </button>
                            );
                        })}
                    </div>
                </div>
            </div> : <Loader/>}
        </div>

        {modalIsOpen && <Modal setIsOpen={closeModal}>
                    {!isSSR && (
            <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy 
                currentBetsData={currentBetsData} 
                refreshData={getAllBets} 
                setIsOpen={closeModal}/>
            </React.Suspense>
          )}
        </Modal>} 
    </div>
}

export default AdminBettingBlock;