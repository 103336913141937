import React from "react";
import styles from './admin-subnavigation.module.less';
import { Link } from 'gatsby';

const adminSubNavigation = () => {
    return (
        <div className={[styles.main, 'pt-3', 'pb-3'].join(' ')}>
            <Link activeClassName={styles.active} className={[styles.link, 'mr-4'].join(' ')} to={"/admin-panel/betting"}>Predict</Link>
            {/* <Link activeClassName={styles.active} className={[styles.link, 'mr-4'].join(' ')} to={"/admin-panel/betting/create"}>Match bet</Link> */}
            <Link activeClassName={styles.active} className={[styles.link, 'mr-4'].join(' ')} to={"/admin-panel/betting/companies"}>Companies</Link>
            <Link activeClassName={styles.active} className={[styles.link].join(' ')} to={"/admin-panel/betting/images"}>Images</Link>
        </div>
    )
}

export default adminSubNavigation;